import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { updateOfferLimitationsAndContacts } from 'shared/apis';
import { schema } from 'shared/schemas/LimitationsAndContacts';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';
import { calcTotalPctOfAuctionFromDivAccts, someDivAccountsWithMaxPctFromAuction } from 'shared/utils/offers';

import { useOfferStateUtils } from 'hooks/offers';

import { generateLimitationAndContactsDefaultValues } from '../utils';

import { buildMutationPayload } from './utils';

export const useLimitationsAndContactsForm = ({ setShowLimitationMismatchAlert, setLimitationMismatchOnProceed, setLimitationMismatchContent }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offer, setOffer, setOnProceed, offerDataLayer } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);
  const { userCanEdit } = useOfferStateUtils({ offer });
  const nextStepUrl = `/user/offers/create/signing/${offer._id}`;

  /* FORM INITIALIZATION */

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    defaultValues: generateLimitationAndContactsDefaultValues(offer),
    resolver: yupResolver(schema),
  });

  const { handleSubmit, formState } = methods;
  const { isDirty } = formState;

  /* ON SUBMIT */
  const { mutate: updateOfferLimitationsAndContactsApi } = useMutation({
    mutationKey: 'updateOfferLimitationsAndContacts',
    mutationFn: updateOfferLimitationsAndContacts,
    onSuccess: (data) => {
      setOffer(data);
      navigate(nextStepUrl);
    },
    onError: (error) => {
      addAlert(errorAlertBuilder.bug(error));
    },
  });

  const shouldOnlyNavigate = useMemo(() => {
    return !isDirty && !userCanEdit;
  }, [isDirty, userCanEdit]);

  const shouldShowLimitationMismatchAlert = useCallback(
    (expectedPayload) => {
      if (!someDivAccountsWithMaxPctFromAuction(offer)) {
        return false;
      }

      const { maxPercentageFromAuction } = expectedPayload;
      if (!maxPercentageFromAuction) {
        return false;
      }

      // Check if the sum of the percentages from the divided accounts is less than the max percentage from the auction
      const splitsMaxPercentageFromAuction = calcTotalPctOfAuctionFromDivAccts(offer);
      if (splitsMaxPercentageFromAuction >= maxPercentageFromAuction) {
        return false;
      }

      return true;
    },
    [offer],
  );

  const setLimitationMismatchAlertState = useCallback(
    (expectedPayload) => {
      const { maxPercentageFromAuction } = expectedPayload;
      const splitsMaxPercentageFromAuction = calcTotalPctOfAuctionFromDivAccts(offer);

      setLimitationMismatchContent(
        t('components.limitationsAndContacts.warnings.limitationMismatch.description', {
          splitsMaxPercentageFromAuction,
          maxPercentageFromAuction,
        }),
      );
      setLimitationMismatchOnProceed(() => () => updateOfferLimitationsAndContactsApi(expectedPayload));
      setShowLimitationMismatchAlert(true);
    },
    [offer, t, setLimitationMismatchOnProceed, setLimitationMismatchContent, setShowLimitationMismatchAlert, updateOfferLimitationsAndContactsApi],
  );

  const onSubmit = useCallback(
    (data) => {
      if (shouldOnlyNavigate) {
        navigate(nextStepUrl);
        return;
      }

      const expectedPayload = buildMutationPayload(offer, data);
      expectedPayload.dataLayer = offerDataLayer;

      if (!shouldShowLimitationMismatchAlert(expectedPayload)) {
        updateOfferLimitationsAndContactsApi(expectedPayload);
        return;
      }

      setLimitationMismatchAlertState(expectedPayload);
    },
    [
      shouldOnlyNavigate,
      offer,
      updateOfferLimitationsAndContactsApi,
      navigate,
      nextStepUrl,
      offerDataLayer,
      setLimitationMismatchAlertState,
      shouldShowLimitationMismatchAlert,
    ],
  );

  useEffect(() => {
    setOnProceed(() => handleSubmit(onSubmit));
  }, [handleSubmit, onSubmit, setOnProceed]);

  return {
    methods,
  };
};
