import { useContext } from 'react';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { HasDefaultLimitations } from './HasDefaultLimitations';
import { NoDefaultLimitations } from './NoDefaultLimitations';

export const DefaultLimitations = () => {
  const { companyDetails } = useContext(CompanyDetailsContext);

  if (companyDetails.defaultLimitations.maxPercentageFromAuction) {
    return <HasDefaultLimitations />;
  } else {
    return <NoDefaultLimitations />;
  }
};
