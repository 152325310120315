import * as yup from 'yup';

import { bnNumber, emailRequired, mobile, personalId } from 'shared/schemas/general';

export const schema = yup.object().shape({
  email: emailRequired,
  mobile,
  personalId,
  bnNumber,
});

export const quickLoginSchema = yup.object().shape({
  personalId,
  signingKey: yup.string().optional(),
});
