import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useContext, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import AddAssociatesModal from './AddAssociatesModal';

const Associates = () => {
  const { t } = useTranslation();
  const { setShowAddAssociatesModal } = useContext(CompanyDetailsContext);
  const { watch, setValue, register } = useFormContext();

  const currentAssociates = watch('associates');

  // Registers 'associates' with React Hook Form since it's not a typical input field.
  // This allows React Hook Form to track its state and include it in form submissions and validations.
  useEffect(() => {
    if (register) {
      register('associates');
    }
  }, [register]);

  return (
    <div className="mt-3">
      <p>{t('screens.company.labels.sharingLimitation')}:</p>
      <ul>
        {currentAssociates?.map((associate, index) => (
          <li key={index}>
            {`${associate.name} (${associate.bnNumber}) `}
            <Button
              variant="danger"
              onClick={() => {
                const newAssociates = [...currentAssociates];
                newAssociates.splice(index, 1);
                setValue('associates', newAssociates, { shouldDirty: true });
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </li>
        ))}
      </ul>
      <Button variant="primary" size="sm" className="ml-2" onClick={() => setShowAddAssociatesModal(true)}>
        {t('screens.company.actions.addAssociates')}
      </Button>
      <AddAssociatesModal />
    </div>
  );
};

export default Associates;
