import uniqBy from 'lodash/uniqBy';
import { useContext, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';

export const DividedAccountsFileErrorsModal = () => {
  const { t } = useTranslation();
  const { dividedAccountsFileErrors, setDividedAccountsFileErrors } = useContext(OfferCreationContext);

  return (
    <Modal
      show={!!dividedAccountsFileErrors}
      onHide={() => {
        setDividedAccountsFileErrors(undefined);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('components.DividedAccountsInputTable.table.errors.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <DividedAccountsErrors />
      </Modal.Body>
    </Modal>
  );
};

const DividedAccountsErrors = () => {
  const { t } = useTranslation();
  const { offer, dividedAccountsFileErrors } = useContext(OfferCreationContext);
  const { type } = offer?.initiation.auctionDetails || {};

  const missingHeadersDisplay = useMemo(() => {
    const missingHeaders = dividedAccountsFileErrors?.missingHeaders;
    if (!missingHeaders?.length) return '';
    const missingHeadersText = missingHeaders.map((header) => t(`components.DividedAccountsInputTable.table.headers.${header}`)).join(' , ');
    return (
      <>
        <p>
          {t('components.DividedAccountsInputTable.table.errors.missingHeaders')}:
          <br />
          {missingHeadersText}
        </p>
      </>
    );
  }, [t, dividedAccountsFileErrors]);

  const DuplicatedHeadersDisplay = useMemo(() => {
    const duplicatedHeaders = dividedAccountsFileErrors?.duplicatedHeaders;
    const duplicatedHeadersAsArray = duplicatedHeaders ? Object.entries(duplicatedHeaders) : [];
    if (!duplicatedHeadersAsArray.length) return '';
    const duplicatedHeadersList = generateDuplicateHeadersListJSX(duplicatedHeadersAsArray, t);
    const header = t('components.DividedAccountsInputTable.table.errors.duplicatedHeaders');
    return (
      <>
        <p>
          {header}
          <br />
          {duplicatedHeadersList}
        </p>
      </>
    );
  }, [t, dividedAccountsFileErrors]);

  const emptyCellsErrorsDisplay = useMemo(() => {
    const emptyCells = dividedAccountsFileErrors?.empty;
    const emptyCellsArray = emptyCells ? Object.entries(emptyCells) : [];
    if (!emptyCellsArray?.length) return '';

    const emptyCellsList = generateEmptyCellsListJSX(emptyCellsArray, t);
    const header = t('components.DividedAccountsInputTable.table.errors.emptyCellsHeaders');
    return (
      <>
        <p>
          {header}
          <br />
          {emptyCellsList}
        </p>
      </>
    );
  }, [t, dividedAccountsFileErrors]);

  const notANumberErrorsDisplay = useMemo(() => {
    const notANumberErrors = dividedAccountsFileErrors?.notANumber;
    const notANumberErrorsArray = notANumberErrors ? Object.entries(notANumberErrors) : [];
    if (!notANumberErrorsArray?.length) return '';

    let limitName;
    switch (type) {
      case auctionTypes.INTEREST:
        limitName = t('common.interest');
        break;
      case auctionTypes.GAP:
        limitName = t('common.gap');
        break;
      case auctionTypes.PRICE:
      default:
        limitName = t('common.price');
        break;
    }
    const header = t('components.DividedAccountsInputTable.table.errors.notANumberHeader', { limitName });

    const notANumberErrorsListJSX = generateAccountAndColumnsGeneralText(notANumberErrorsArray, t);

    return (
      <>
        <p>
          {header}
          <br />
          {notANumberErrorsListJSX}
        </p>
      </>
    );
  }, [t, dividedAccountsFileErrors, type]);

  const badBankNameErrorsDisplay = useMemo(() => {
    const badBankNameErrors = dividedAccountsFileErrors?.badBankName;
    const badBankNameErrorsArray = badBankNameErrors ? Object.entries(badBankNameErrors) : [];
    if (!badBankNameErrorsArray?.length) return '';

    const header = t('components.DividedAccountsInputTable.table.errors.badBankNameHeader');

    const badBankNameErrorsListJSX = badBankNameErrorsArray.map((row) => {
      const [accountName, badAccountColumns] = row;
      let text = `${t('components.DividedAccountsInputTable.table.errors.accountNamePrefix')} '${accountName}' `;
      text += `${t('components.DividedAccountsInputTable.table.errors.badBankNamePrefix')}: `;
      text += uniqBy(badAccountColumns).join(', ');
      return (
        <>
          <span>{text}</span>
          <br />
        </>
      );
    });

    return (
      <>
        <p>
          {header}
          <br />
          {badBankNameErrorsListJSX}
        </p>
      </>
    );
  }, [t, dividedAccountsFileErrors]);

  return (
    <>
      {missingHeadersDisplay}
      {DuplicatedHeadersDisplay}
      {emptyCellsErrorsDisplay}
      {notANumberErrorsDisplay}
      {badBankNameErrorsDisplay}
    </>
  );
};

const generateDuplicateHeadersListJSX = (duplicatedHeadersAsArray, t) => {
  const result = duplicatedHeadersAsArray.map((duplicateEntry) => {
    const [headerName, duplications] = duplicateEntry;
    let text = t('components.DividedAccountsInputTable.table.errors.duplicatedHeadersMessagePrefix') + ": '";
    text += t(`components.DividedAccountsInputTable.table.headers.${headerName}`);
    text += "' " + t('components.DividedAccountsInputTable.table.errors.duplicatedHeadersMessage');
    text += " : '";
    text += duplications.join("', '");
    text += "'";
    return (
      <>
        <span>{text}</span>
        <br />
      </>
    );
  });
  return result;
};

const generateEmptyCellsListJSX = (emptyCellsArray, t) => {
  const result = emptyCellsArray.map((row) => {
    const [accountName, emptyColumns] = row;
    let text = `${t('components.DividedAccountsInputTable.table.errors.accountNamePrefix')} '${accountName}' `;
    text += `${t('components.DividedAccountsInputTable.table.errors.emptyCellsPrefix2')}: '`;
    text += emptyColumns.map((column) => t(`components.DividedAccountsInputTable.table.headers.${column}`)).join(', ');
    text += "' " + t('components.DividedAccountsInputTable.table.errors.emptyCellsPrefix3');
    return (
      <>
        <span>{text}</span>
        <br />
      </>
    );
  });
  return result;
};

const generateAccountAndColumnsGeneralText = (notANumberErrorsArray, t) => {
  const result = notANumberErrorsArray.map((row) => {
    const [accountName, columns] = row;
    let text = `${t('components.DividedAccountsInputTable.table.errors.accountNamePrefix')} '${accountName}': `;
    text += `${t('components.DividedAccountsInputTable.table.errors.notANumberPrefix')}  '${columns.join("', '")}'`;
    return (
      <>
        <span>{text}</span>
        <br />
      </>
    );
  });
  return result;
};
