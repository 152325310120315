import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { offerProgressCheckpoints } from 'shared/const/offerCreationStages';

import { Tooltip } from 'components/UI/Tooltip';

const colorMap = {
  [offerProgressCheckpoints.scopeAndAccountsFilled]: '#e19eff',
  [offerProgressCheckpoints.limitationsAndContactsFilled]: '#FF6347',
  [offerProgressCheckpoints.sentToSignatories]: '#FFA500',
  [offerProgressCheckpoints.signatoriesSigned]: '#FFD700',
  [offerProgressCheckpoints.offerSent]: '#32CD32',
};

export const BlameSection = ({ row, progressKey }) => {
  const { t } = useTranslation();
  const { versionSummaryDetails } = row;

  const relativeVersionSummary = versionSummaryDetails.findLast((versionSummary) => {
    const newValues = versionSummary.newValues;
    return newValues.hasOwnProperty(`progress.${progressKey}`);
  });

  if (!relativeVersionSummary) {
    return null;
  }

  const {
    updatedAt,
    versionBlame: { email },
  } = relativeVersionSummary;

  return (
    <ul>
      <li>
        {t('components.ProgressCell.BlameSection.updatedAt')}: {dayjs(updatedAt).format('DD/MM/YYYY HH:mm')}
      </li>
      <li>
        {t('components.ProgressCell.BlameSection.updatedBy')}: {email}
      </li>
    </ul>
  );
};

export const ProgressCell = ({ row }) => {
  const { t } = useTranslation();
  const { progress } = row;
  const lis = Object.keys(progress)
    .filter((progressKey) => progressKey !== '_id')
    .map((progressKey) => {
      const icon = progress[progressKey] ? faCheckCircle : faTimesCircle;
      const TooltipTrigger = (
        <FontAwesomeIcon
          icon={icon}
          className={`h-4 w-4 border-[2px] border-slate-900 rounded-xl bg-slate-900 ${progress[progressKey] ? 'opacity-100' : 'opacity-50'}`}
          style={{ color: colorMap[progressKey] }}
        />
      );

      const TooltipContent = (
        <div>
          <div>
            {t(`screens.auctions.table.progress.${progressKey}`)}: {progress[progressKey].toString()}
            <BlameSection row={row} progressKey={progressKey} />
          </div>
        </div>
      );

      return (
        <li key={progressKey}>
          <Tooltip Content={TooltipContent} Trigger={TooltipTrigger} />
        </li>
      );
    });

  return <ul className="text-xs flex gap-1">{lis}</ul>;
};
