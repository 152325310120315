import * as yup from 'yup';

import { baseSchema as baseBankSchema } from 'shared/schemas/addCompanyBankAccount';
import {
  accreditedInvestorCertExpiryDateSchema,
  bnNumberRequired,
  companyNameRequired,
  domain,
  email,
  maxPercentageFromAuctionSchema,
  mobile,
  name,
  personalId,
  role,
  uniqueEmailsArray,
} from 'shared/schemas/general';

// hack for i18n ally inline translations
const t = (key) => key;

export const employeeSchema = yup.object().shape({
  firstName: name,
  lastName: name,
  email: email.test('isUnique', t('validationErrors.email.isUnique'), function (value) {
    const { from } = this;
    const users = from?.[1]?.value?.users;
    if (!users) return true;
    return users.filter((user) => user.email === value).length <= 1;
  }),
  personalId,
  mobile,
  role: role,
});

export const schema = yup.object().shape({
  companyName: companyNameRequired,
  bnNumber: bnNumberRequired,
  domain,
  bankAccounts: yup.array().of(baseBankSchema),
  users: yup.array().of(employeeSchema),
  defaultEmailsForAuctionResults: uniqueEmailsArray,
  accreditedInvestorCertKey: yup.string().nullable(), // this is just the file name, that's already uploaded
  accreditedInvestorCertExpiryDate: accreditedInvestorCertExpiryDateSchema,
  defaultLimitations: yup.object().shape({
    maxPercentageFromAuction: maxPercentageFromAuctionSchema,
  }),
});

export const roleSchema = yup.object().shape({
  role: role,
  bnNumber: bnNumberRequired,
  companyName: companyNameRequired,
});
