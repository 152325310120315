import { faCheck, faEnvelope, faFileExcel, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import numbro from 'numbro';
import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';

import { BID_INPUT_FIELDS, BID_INPUT_RESULTS_FIELDS } from 'shared/const/bidInputFieldType';
import { underwriterOfferStatuses } from 'shared/const/offerCreationStages';
import { buildAuctionTitleGeneric } from 'shared/utils/auctions';
import { formatBidValue } from 'shared/utils/offers';
import { getBidsResultsFieldArray } from 'shared/utils/offers/bidResults';

import { useDownloadDividedAccountsExcel } from 'hooks/offers';
import { useOpenPdfFactory } from 'hooks/useOpenPdfFactory';

import { AuctionAttachments } from 'components/common/AuctionAttachments';
import { Tooltip } from 'components/UI/Tooltip';

export const useOfferTableColumns = () => {
  const { t } = useTranslation();
  const { activeContracts } = useContext(AuthContext);
  const investingCompanyColumnName = 'investingCompany';
  const { openOfferProposalDocumentAsPdf, openMosadiAcceptanceMessageDocumentAsPdf } = useOpenPdfFactory();
  const { downloadDividedAccountsExcel } = useDownloadDividedAccountsExcel();

  const result = [
    {
      Header: 'name',
      accessor: 'initiation.auctionDetails.issuedCompanyName',
      Cell: (row) => {
        const { issuedCompanyName, unitStructures } = row.initiation.auctionDetails;
        const value = buildAuctionTitleGeneric({ issuedCompanyName, unitStructures, t });
        return value;
      },
    },
    {
      Header: 'institutionalPhaseDate',
      accessor: 'initiation.auctionDetails.closeTime',
      Cell: (row) => {
        const value = dayjs(row.initiation.auctionDetails.closeTime).format('DD/MM/YYYY');
        return value;
      },
    },
    {
      Header: 'initiator',
      accessor: 'initiation.initiator.firstName',
      Cell: (row) => {
        const { firstName, lastName } = row.initiation.initiator;
        const value = `${firstName} ${lastName}`;
        return value;
      },
    },
    {
      Header: 'investingCompany',
      accessor: 'initiation.investingCompany.name',
      Cell: (row) => {
        const value = row.initiation.investingCompany.name;
        return value;
      },
    },
    generateBidsColumn(BID_INPUT_FIELDS.LIMIT, BID_INPUT_RESULTS_FIELDS.LIMIT),
    generateBidsColumn(BID_INPUT_FIELDS.AMOUNT, BID_INPUT_RESULTS_FIELDS.REQUESTED_AMOUNT),
    generateInstitutionalAllocationColumn(),
    {
      Header: 'orderPdf',
      accessor: '',
      noSort: true,
      Cell: (row) => {
        const offerDocument = !!row.internalApproval.proposalDocument?.pdf;
        const haveDividedAccountingExcel = row.internalApproval.dividedAccountingExcel;
        const offerAccepted = row.underwriterOfferStatus === underwriterOfferStatuses.ACCEPTED;

        const tooltipTrigger = <FontAwesomeIcon className="text-green-400 cursor-default" icon={faCheck} size="xl" />;

        const pdfTooltipTrigger = (
          <Button variant="secondary" onClick={() => openOfferProposalDocumentAsPdf(row)}>
            <FontAwesomeIcon icon={faFilePdf} />
          </Button>
        );

        const excelTooltipTrigger = (
          <Button variant="secondary" onClick={() => downloadDividedAccountsExcel(row)}>
            <FontAwesomeIcon icon={faFileExcel} />
          </Button>
        );

        const result = (
          <>
            <div className="d-flex gap-2">
              {offerDocument && <Tooltip Content={t('components.pastOffersTable.tooltips.proposalDocumentView')} Trigger={pdfTooltipTrigger} />}
              {haveDividedAccountingExcel?.length > 0 && (
                <Tooltip Content={t('components.pastOffersTable.tooltips.excelDownload')} Trigger={excelTooltipTrigger} />
              )}
              {offerAccepted && <Tooltip Content={t('components.pastOffersTable.tooltips.offerAccepted')} Trigger={tooltipTrigger} />}
            </div>
          </>
        );
        return result;
      },
    },
    {
      Header: 'attachments',
      accessor: 'initiation.auctionDetails.attachments',
      Cell: (row) => {
        return <AuctionAttachments auction={row.initiation.auctionDetails} />;
      },
      noSort: true,
    },
    {
      Header: 'institutionalAcceptanceNotification',
      accessor: '',
      noSort: true,
      Cell: (row) => {
        const acceptanceMessage = !!row.results?.mosadi?.acceptanceMessage?.pdf;

        const acceptanceMessageTooltipTrigger = (
          <Button variant="secondary" onClick={() => openMosadiAcceptanceMessageDocumentAsPdf(row)}>
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>
        );
        const result = (
          <>
            <div className="d-flex gap-2">
              {acceptanceMessage && (
                <Tooltip Content={t('components.pastOffersTable.tooltips.acceptanceMessageView')} Trigger={acceptanceMessageTooltipTrigger} />
              )}
            </div>
          </>
        );
        return result;
      },
    },
  ];

  if (activeContracts?.length === 1) {
    const investingCompanyColumnIndex = result.findIndex((column) => column.Header === investingCompanyColumnName);
    result.splice(investingCompanyColumnIndex, 1);
  }

  return result;
};

const generateBidsColumn = (bidField, resultBidField) => {
  return {
    Header: bidField,
    accessor: '',
    noSort: true,
    tdStyle: { padding: '0' },
    Cell: (row) => {
      const valuesList = getBidsResultsFieldArray(row, bidField, resultBidField) || [];
      const result = (
        <div className="flex flex-col h-full divide-y divide-gray-200">
          {valuesList.map((value, index) => {
            return (
              <div className="flex-grow items-center flex p-2" key={index}>
                <p>{formatBidValue(value, bidField, row)}</p>
              </div>
            );
          })}
        </div>
      );
      return result;
    },
  };
};

const generateInstitutionalAllocationColumn = () => {
  return {
    Header: 'institutionalAllocation',
    accessor: '',
    noSort: true,
    tdStyle: { padding: '0' },
    Cell: (row) => {
      const bids = row.results?.mosadi?.bids;
      if (!bids) return null;

      const result = (
        <div className="flex flex-col h-full divide-y divide-gray-200">
          {bids.map(({ requestedAmount, receivedAmount, isWon }, index) => {
            const allocationPercentage = receivedAmount / requestedAmount;
            const allocationPercentageFormatted = numbro(allocationPercentage).format('0.00%');
            // if offer is lost, show red text
            // if offer is won and fully allocated, show green text
            // if offer is won and partially allocated, show yellow text
            const textColor = !isWon ? 'text-danger' : allocationPercentage === 1 ? 'text-green-400' : 'text-warning';

            const trigger = <p className={textColor}>{formatBidValue(receivedAmount, BID_INPUT_FIELDS.AMOUNT, row)}</p>;

            return (
              <div className="flex-grow items-center flex" key={index}>
                <Tooltip Content={allocationPercentageFormatted} Trigger={trigger} />
              </div>
            );
          })}
        </div>
      );
      return result;
    },
  };
};
