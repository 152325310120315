import { useQuery } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Button, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { getAuctionById } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { buildAuctionTitle } from 'shared/utils/auctions';

import { useOfferDeactivation, useOfferEditMode, useOfferStateUtils } from 'hooks/offers';

import { AuctionAttachments } from 'components/common/AuctionAttachments';
import { AuctionTimer } from 'components/LiveAuctionCard/AuctionTimer';
import { ProtectedImage } from 'components/UI/ProtectedImage';

const AuctionTitle = ({ auctionId }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContentContext);
  const { offer } = useContext(OfferCreationContext);
  const [auction, setAuction] = useState(null);
  const { OfferEditModalConfirm, setShowConfirmEditModal, setShowCancelEditModal } = useOfferEditMode(offer);
  const { DeactivationModal, setShowDeactivationModal } = useOfferDeactivation({ onSuccess: () => navigate('/user/offers/manage') });
  const { isUserOfferInitiator, isEditingMode, userCanEdit } = useOfferStateUtils({ offer, auction });
  auctionId = auctionId ? auctionId : offer?.initiation.auctionDetails.auctionId;

  /* server side communication */
  const { error, data } = useQuery({
    queryKey: ['getAuctionById', auctionId],
    queryFn: () => getAuctionById(auctionId),
    enabled: !!auctionId,
  });

  useEffect(() => {
    if (error) {
      addAlert({
        title: t('screens.ChooseCompanyForNewOffer.errors.auctionNotFound.title'),
        description: t('screens.ChooseCompanyForNewOffer.errors.auctionNotFound.description'),
        error: error,
        type: ALERT_TYPES.ERROR,
      });
      navigate('/');
    }
  }, [error, addAlert, navigate, t]);

  useEffect(() => {
    if (data) {
      setAuction(data);
    }
  }, [data]);

  //BUILD HEADERS
  const firstHeader = useMemo(() => {
    if (!auction) return;

    return buildAuctionTitle({ auction, t });
  }, [auction, t]);

  const secondHeader = useMemo(() => {
    if (!offer) return;
    let header = `${t('screens.OrderScopeAndAccounts.secondHeader')} `;
    const companyName = offer?.initiation?.investingCompany?.name;
    if (!companyName) {
      return `${header}.`;
    }

    return (
      <>
        {header} <span className="font-bold">{companyName}</span>
      </>
    );
  }, [offer, t]);

  const EditButton = useCallback(() => {
    if (userCanEdit && !isEditingMode) return null;

    const onClick = isEditingMode ? setShowCancelEditModal : setShowConfirmEditModal;
    const text = isEditingMode ? t('screens.LimitationsAndContacts.cancelEditOffer') : t('screens.LimitationsAndContacts.editOffer');

    return (
      <Button variant="warning whitespace-nowrap" className="!text-sm ml-2" onClick={onClick}>
        {text}
      </Button>
    );
  }, [setShowConfirmEditModal, setShowCancelEditModal, userCanEdit, isEditingMode, t]);

  //JSX
  if (!auction) {
    return <div>...loading</div>;
  }

  return (
    <Container className="flex flex-col md:flex-row w-100 justify-between mt-5 mb-4 gap-4">
      <div className="flex flex-col grow align-bottom">
        <div id="LogoAndHeader" className="flex flex-row gap-4 align-items-center">
          <ProtectedImage
            imageSrc={auction?.issuedCompanyLogoUrl}
            alt="Company Logo"
            withFallbackImage
            className="h-[55px] max-w-[100px] w-100 object-contain"
          />
          <p className="font-medium	 text-3xl">{firstHeader}</p>
          <AuctionTimer closeTime={auction.closeTime} />
        </div>
        <p id="SecondHeader" className="font-normal	text-lg mt-3">
          {secondHeader}
        </p>
        <div id="auctionAttachments" className="flex gap-1 text-lg">
          {t('components.liveAuctionCard.labels.attachments')}:{' '}
          <AuctionAttachments auction={auction} direction="row" addCommasSeparator extraClassNames="items-center" />
        </div>
      </div>
      {isUserOfferInitiator && (
        <div className="flex items-end gap-2">
          <Button variant="outline-danger whitespace-nowrap" className="!text-sm" onClick={() => setShowDeactivationModal(true)}>
            {t('screens.LimitationsAndContacts.cancelOffer')}
          </Button>

          <EditButton />
        </div>
      )}
      <DeactivationModal />
      <OfferEditModalConfirm />
    </Container>
  );
};

export default AuctionTitle;
