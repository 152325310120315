import * as yup from 'yup';

import { pinCode } from 'shared/schemas/general';

export const schema = yup.object().shape({
  smsPinCode: pinCode,
  emailPinCode: pinCode,
});

export const mobileOnlySchema = yup.object().shape({
  smsPinCode: pinCode,
});

export const fallbackKeySchema = yup.object().shape({
  signingKey: yup.string().required(),
});
