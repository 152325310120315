import { useContext, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Form, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { useOfferStateUtils } from 'hooks/offers';

import OfferContacts from 'components/Offers/OfferContacts/OfferContacts';
import OfferLimitations from 'components/Offers/OfferLimitations/OfferLimitations';
import Box from 'components/UI/Box';
import ModalConfirm from 'components/UI/ModalConfirm';

import { useLimitationsAndContactsForm } from './useLimitationsAndContactsForm';

export const LimitationsAndContacts = () => {
  const { t } = useTranslation();
  const { offer } = useContext(OfferCreationContext);
  const { userCanEdit } = useOfferStateUtils({ offer });
  const [showLimitationMismatchAlert, setShowLimitationMismatchAlert] = useState(false);
  const [limitationMismatchOnProceed, setLimitationMismatchOnProceed] = useState(() => () => {});
  const [limitationMismatchContent, setLimitationMismatchContent] = useState('');
  const { methods } = useLimitationsAndContactsForm({
    setShowLimitationMismatchAlert,
    setLimitationMismatchOnProceed,
    setLimitationMismatchContent,
  });

  const onCloseLimitationMismatchAlert = () => {
    setShowLimitationMismatchAlert(false);
  };

  return (
    <FormProvider {...methods}>
      <Form>
        <fieldset disabled={!userCanEdit}>
          <Container className="grid grid-cols-1 md:grid-cols-2 gap-4 pb-[130px]">
            <Box>
              <OfferLimitations />
            </Box>
            <Box>
              <OfferContacts />
            </Box>
          </Container>
        </fieldset>
      </Form>

      <ModalConfirm
        show={showLimitationMismatchAlert}
        onClose={onCloseLimitationMismatchAlert}
        title={t('common.attention')}
        content={limitationMismatchContent}
        proceed={t('common.proceed')}
        onProceed={limitationMismatchOnProceed}
        variant={'warning'}
        onCancel={onCloseLimitationMismatchAlert}
      />
    </FormProvider>
  );
};
