import { faBarsProgress, faCheck, faFileSignature, faTimes, faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { underwriterOfferStatuses } from 'shared/const/offerCreationStages';
import { isEditingMode, isOfferInProgress } from 'shared/utils/offers';

import { useOfferStateUtils } from 'hooks/offers';

export const StatusCell = ({ offer }) => {
  const { t } = useTranslation();
  const { userCanSign, isSentToSignatories, hasSignatoriesSigned } = useOfferStateUtils({ offer });

  const buildStatusCell = (offer) => {
    if (isEditingMode(offer)) {
      return (
        <span>
          <FontAwesomeIcon icon={faBarsProgress} className="text-warning" /> {t('components.LiveAuctionCard.statuses.editMode')}
        </span>
      );
    }

    if (isOfferInProgress(offer)) {
      if (hasSignatoriesSigned) {
        return (
          <span>
            <FontAwesomeIcon icon={faCheck} className="text-success" /> {t('components.LiveAuctionCard.statuses.ready_to_be_sent')}
          </span>
        );
      }

      if (isSentToSignatories) {
        const text = userCanSign ? t('components.LiveAuctionCard.statuses.sign') : t('components.LiveAuctionCard.statuses.signatures');

        return (
          <span>
            <FontAwesomeIcon icon={faFileSignature} className="text-warning" /> {text}
          </span>
        );
      }

      return (
        <span>
          <FontAwesomeIcon icon={faBarsProgress} className="text-warning" /> {t('components.LiveAuctionCard.statuses.initiated')}
        </span>
      );
    }

    switch (offer.underwriterOfferStatus) {
      // sent successfully to the underwriter
      case underwriterOfferStatuses.PENDING:
      case underwriterOfferStatuses.ACCEPTED:
        if (offer?.originalOfferId) {
          return (
            <span>
              <FontAwesomeIcon icon={faCheck} className="text-success" /> {t('components.LiveAuctionCard.statuses.updatedAndSent')}
            </span>
          );
        }

        return (
          <span>
            <FontAwesomeIcon icon={faCheck} className="text-success" /> {t('components.LiveAuctionCard.statuses.sent')}
          </span>
        );
      // something went wrong
      case underwriterOfferStatuses.REJECTED:
        return (
          <span>
            <FontAwesomeIcon icon={faTriangleExclamation} className="text-danger" /> {t('components.LiveAuctionCard.statuses.rejected')}
          </span>
        );
      default:
        console.error('Unknown underwriter offer status', offer.underwriterOfferStatus);
        return (
          <span>
            <FontAwesomeIcon icon={faTimes} className="text-danger" /> {t('components.LiveAuctionCard.statuses.notSent')}
          </span>
        );
    }
  };

  if (!offer) return null;

  return buildStatusCell(offer);
};
