import { useContext } from 'react';

import { AuthContext } from 'context/Auth';

import {
  allowOfferCancellation,
  allowSendingWithMissingSignatures,
  hasSignatoriesSigned,
  hasSignatures,
  isAcceptedByUnderwriter,
  isAuctionActive,
  isEditingMode,
  isOfferComplete,
  isOfferReadyForView,
  isRejectedByUnderwriter,
  isSentToSignatories,
  isUserOfferInitiator,
  offerReadyForSigning,
  userCanCancelOffer,
  userCanQuickSend,
  userCanSend,
  userCanSign,
  userHasSigned,
  userIsLastSignatory,
  userIsOnlySignatory,
  userIsSignatory,
  userShouldQuickSend,
} from 'shared/utils/offers';

export const useOfferStateUtils = ({ offer, auction }) => {
  const { isAdminAtCompany, userId } = useContext(AuthContext);

  const res = {
    /* auction statuses */
    isAuctionActive: isAuctionActive(auction),
    /* offer status */
    isOfferRejected: isRejectedByUnderwriter(offer),
    isOfferAccepted: isAcceptedByUnderwriter(offer),
    hasSignatures: hasSignatures(offer),
    isSentToSignatories: isSentToSignatories(offer),
    hasSignatoriesSigned: hasSignatoriesSigned(offer),
    isEditingMode: isEditingMode(offer),
    isOfferComplete: isOfferComplete(offer),
    /* logic relations */
    isOfferReadyForView: isOfferReadyForView(offer),
    offerReadyForSigning: offerReadyForSigning(offer),
    allowOfferCancellation: allowOfferCancellation(offer),
    allowSendingWithMissingSignatures: allowSendingWithMissingSignatures(offer, userId),
    /* user relations  */
    isUserOfferInitiator: isUserOfferInitiator(offer, userId),
    userCanCancelOffer: userCanCancelOffer(offer, userId, isAdminAtCompany),
    userCanEdit: isUserOfferInitiator(offer, userId) && !(isOfferComplete(offer) || isSentToSignatories(offer)),
    userHasSigned: userHasSigned(offer, userId),
    userIsSignatory: userIsSignatory(offer, userId),
    userIsOnlySignatory: userIsOnlySignatory(offer, userId),
    userIsLastSignatory: userIsLastSignatory(offer, userId),
    userCanSign: userCanSign(offer, userId),
    userCanSend: userCanSend(offer, userId),
    userShouldQuickSend: userShouldQuickSend(offer, userId),
    userCanQuickSend: userCanQuickSend(offer, userId),
  };

  return res;
};
