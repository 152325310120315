export const validateLimitGreaterThanMinPrice = (value, minPrice) => {
  return value > 0 && value >= minPrice;
};

export const validateLimitLessThanMaxInterest = (value, maxInterest) => {
  if (maxInterest === 0 || maxInterest) {
    return value <= maxInterest;
  }

  // if maxInterest is not defined, it means that there is no max interest...
  return true;
};
