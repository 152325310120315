import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { DefaultLimitationsEditMode } from '../../CompanyForm/CompanyConnectionEditMode';

import Associates from './Associates';
import AuctionResultsEmails from './AuctionResultsEmails';
import { DefaultLimitations } from './DefaultLimitations';
import RelatedDistributerList from './RelatedDistributerList';

export const CompanyConnections = ({ editDefaultLimitations = false }) => {
  const { companyDetails, companyDetailsIsLoading, companyDetailsError } = useContext(CompanyDetailsContext);

  if (companyDetailsIsLoading) {
    return (
      <div className="d-flex justify-content-center">
        <Spinner animation="border" variant="primary" />
      </div>
    );
  }

  if (!companyDetails || companyDetailsError) {
    /* error will be handled by the context */
    return null;
  }

  return (
    <>
      <RelatedDistributerList />
      <Associates />
      <AuctionResultsEmails />
      {editDefaultLimitations ? <DefaultLimitationsEditMode /> : <DefaultLimitations />}
    </>
  );
};
