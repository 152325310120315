import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext } from 'context/CompanyDetails';

export const HasDefaultLimitations = () => {
  const { t } = useTranslation();
  const { companyDetails } = useContext(CompanyDetailsContext);

  return (
    <div className="mt-3">
      <p className="underline font-bold">{t('screens.company.labels.defaultLimitations')}</p>
      <p>
        {t('screens.LimitationsAndContacts.form.limitations.labels.maxAuctionAllocationPercent')}
        <span className="inline max-w-[150px] ms-2">{companyDetails.defaultLimitations.maxPercentageFromAuction}%</span>
      </p>
      <p>{t('screens.LimitationsAndContacts.form.limitations.labels.ofTotalAllocatedInAuction')}.</p>
      {companyDetails.defaultLimitations.isMutualLimitation && <p>{t('screens.company.labels.isMutualLimitation')}.</p>}
    </div>
  );
};
