import isNumber from 'lodash/isNumber';
import { useContext, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';
import { calculateLimits } from 'shared/utils/offers';
import { validateLimitGreaterThanMinPrice, validateLimitLessThanMaxInterest } from 'shared/utils/offers';

import { checkGap } from 'components/Offers/utils/gaps';

import { useScopeAndAccountsErrors } from './useScopeAndAccountsErrors';

export const useBidInputValidation = ({ fieldName, inputType }) => {
  const { setError, clearError } = useScopeAndAccountsErrors(fieldName);
  const { t } = useTranslation();

  const { offer } = useContext(OfferCreationContext);
  const { type, biddingLimitations, unitStructures } = offer?.initiation.auctionDetails || {};
  const { priceGap } = biddingLimitations || {};
  const { minPrice, maxInterest } = calculateLimits(biddingLimitations, unitStructures);

  const { watch } = useFormContext();
  const watchFieldName = watch(fieldName);
  const watchTableStr = watch('tableStr');

  useEffect(() => {
    /* REQUIRED AND NUMBER VALIDATION */
    const errorMsg = t('components.DividedAccountsInputTable.table.errors.required');
    const haveValue = watchFieldName?.toString().length;
    haveValue ? clearError(errorMsg) : setError(errorMsg);

    /* only digits */
    const notANumberErrorMsg = t('components.DividedAccountsInputTable.table.errors.notANumber');
    // we only care its not a number if a value exists
    if (haveValue) {
      const isNumberRes = isNumber(watchFieldName);
      isNumberRes ? clearError(notANumberErrorMsg) : setError(notANumberErrorMsg);
    } else {
      clearError(notANumberErrorMsg);
    }

    if (inputType === 'amount') {
      const isIntegerErrorMsg = t('components.DividedAccountsInputTable.table.errors.amountNotAnInteger');
      const isInteger = Number.isInteger(watchFieldName) && watchFieldName > 0;
      isInteger ? clearError(isIntegerErrorMsg) : setError(isIntegerErrorMsg);
      return;
    }

    /* LIMIT VALUE VALIDATION */
    if (inputType !== 'limit' || !type) return;
    if (!watchFieldName) return;

    /* gap */
    if (priceGap > 0) {
      const gapErrorMsg = t('components.DividedAccountsInputTable.table.errors.priceGap');
      const isValidGap = checkGap(watchFieldName, priceGap, type, { maxInterest, minPrice });
      isValidGap ? clearError(gapErrorMsg) : setError(gapErrorMsg);
    }

    switch (type) {
      case auctionTypes.PRICE:
        const minPriceErrorMsg = t('components.DividedAccountsInputTable.table.errors.minPrice');
        const priceIsHigherThanMin = validateLimitGreaterThanMinPrice(watchFieldName, minPrice);
        priceIsHigherThanMin ? clearError(minPriceErrorMsg) : setError(minPriceErrorMsg);
        break;
      case auctionTypes.INTEREST:
      case auctionTypes.GAP:
        const maxInterestErrorMsg = t('components.DividedAccountsInputTable.table.errors.maxInterest');
        const interestIsLowerThenMax = validateLimitLessThanMaxInterest(watchFieldName, maxInterest);
        interestIsLowerThenMax ? clearError(maxInterestErrorMsg) : setError(maxInterestErrorMsg);
        break;
      default:
        throw new Error('Invalid auction type');
    }
  }, [setError, clearError, watchFieldName, type, priceGap, maxInterest, minPrice, inputType, t, watchTableStr]);
};
