import { useQueryClient } from '@tanstack/react-query';
import compact from 'lodash/compact';
import some from 'lodash/some';
import { useCallback } from 'react';
import { Dropdown, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useOfferDeactivation, useOfferEditMode, useOfferStateUtils, useSendOfferMutation } from 'hooks/offers';
import { useOpenPdfFactory } from 'hooks/useOpenPdfFactory';

const ManageOfferDropdown = ({ auction, offer, offerDataForAnalytics }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const {
    DeactivationModal,
    setShowDeactivationModal,
    isPending: deactivateOfferIsPending,
  } = useOfferDeactivation({
    offer,
    onSuccess: () => queryClient.invalidateQueries(['getActiveAuctionsOfferState']),
  });
  const { OfferEditModalConfirm, setShowConfirmEditModal, setShowCancelEditModal } = useOfferEditMode(offer);
  const { mutate: sendOfferMutation, Modal: OfferSendingModal, isPending: sendOfferIsPending } = useSendOfferMutation({ offer });
  const { openOfferProposalDocumentAsPdf } = useOpenPdfFactory();

  const {
    userCanEdit,
    userHasSigned,
    allowOfferCancellation,
    isUserOfferInitiator,
    userCanCancelOffer,
    isOfferComplete,
    userCanSign,
    isOfferReadyForView,
    offerReadyForSigning,
    userCanSend,
    isEditingMode,
    userCanQuickSend,
  } = useOfferStateUtils({
    offer,
    auction,
  });

  // Actions

  const redirectToEditScreen = useCallback(() => {
    navigate(`/user/offers/create/order-scope-and-accounts/${offer._id}`);
  }, [navigate, offer._id]);

  const handleOnEdit = useCallback(() => {
    if (!userCanEdit) {
      setShowConfirmEditModal(true);
    } else {
      redirectToEditScreen();
    }
  }, [redirectToEditScreen, userCanEdit, setShowConfirmEditModal]);

  const divider = {
    divider: true,
  };

  // Controls
  const controls = [
    userCanSign && {
      label: t('components.ManageOfferDropdown.controls.signOffer'),
      onClick: () => {
        navigate(`/user/offers/create/signing/${offer._id}`);
      },
      disabled: userHasSigned,
    },
    userCanSend && {
      label: t('components.ManageOfferDropdown.controls.viewFinalOffer'),
      onClick: () => {
        navigate(`/user/offers/create/sending/${offer._id}`);
      },
    },
    userCanQuickSend && {
      label: t('components.ManageOfferDropdown.controls.sendOffer'),
      onClick: () => {
        const expectedPayload = {
          offerId: offer._id,
          dataLayer: offerDataForAnalytics,
        };
        sendOfferMutation(expectedPayload);
      },
    },
    divider,
    isOfferReadyForView && {
      label: t('components.ManageOfferDropdown.controls.viewOffer'),
      onClick: () => {
        navigate(`/user/offers/create/signing/${offer._id}`);
      },
    },
    isOfferComplete && {
      label: t('components.ManageOfferDropdown.controls.viewOfferPDF'),
      onClick: () => openOfferProposalDocumentAsPdf(offer),
    },
    divider,
    isUserOfferInitiator && {
      label: t('components.ManageOfferDropdown.controls.editOffer'),
      onClick: handleOnEdit,
    },
    isUserOfferInitiator &&
      isEditingMode && {
        label: t('components.ManageOfferDropdown.controls.cancelEditOffer'),
        onClick: setShowCancelEditModal,
      },

    divider,
    isUserOfferInitiator &&
      offerReadyForSigning && {
        label: t('components.ManageOfferDropdown.controls.sendOffer'),
        onClick: () => {
          navigate(`/user/offers/create/sending/${offer._id}`);
        },
      },
    divider,
    userCanCancelOffer && {
      label: t('components.ManageOfferDropdown.controls.cancelOffer'),
      onClick: () => setShowDeactivationModal(true),
      disabled: allowOfferCancellation,
    },
  ];

  const compactControls = useCallback((controls) => {
    /* first compact the controls */
    const compactedControls = compact(controls);

    /* then compact the dividers */
    /* if the first or last item is a divider, remove it */
    /* if the item before or after a divider is a divider, remove it */
    const filteredControls = compactedControls.reduce((filteredControls, control, index, controls) => {
      if (control.divider) {
        if (index === 0 || index === controls.length - 1) {
          return filteredControls;
        }
        if (controls[index + 1]?.divider) {
          return filteredControls;
        }
      }
      filteredControls.push(control);
      return filteredControls;
    }, []);

    /* finally re-compact the controls */
    const finalControls = compact(filteredControls);

    return finalControls;
  }, []);

  const compactedControls = compactControls(controls);

  if (!some(compactedControls)) {
    return null;
  }

  const isPending = deactivateOfferIsPending || sendOfferIsPending;

  return (
    <Dropdown className="!text-sm">
      <Dropdown.Toggle variant="outline-primary" id="dropdown-basic" className="!text-sm">
        {isPending ? <Spinner size="sm" animation="border" /> : t('components.ManageOfferDropdown.controls.manageOffer')}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {compactedControls.map((control, index) => {
          if (control.divider) {
            return <Dropdown.Divider key={index} />;
          }

          return (
            <Dropdown.Item key={index} onClick={control.onClick} disabled={control.disabled}>
              {control.label}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
      <OfferEditModalConfirm />
      <DeactivationModal />
      <OfferSendingModal />
    </Dropdown>
  );
};

export default ManageOfferDropdown;
