import { ErrorMessage } from '@hookform/error-message';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Alert, Button, Container, Form, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useNavigate } from 'react-router-dom';
import { StringParam, useQueryParam } from 'use-query-params';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';

import { startQuickLogin, testRefreshToken } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { customLocalStorage } from 'shared/customLocalStorage';
import { quickLoginSchema } from 'shared/schemas/auth';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { useDirection } from 'hooks/useDirection';
import { useWindowSize } from 'hooks/useWindowSize';

import backgroundEn from '../../../asset/login/background-en.svg';
import backgroundHe from '../../../asset/login/background-he.svg';
import smartbullLogo from '../../../asset/logo-online.svg';

import { SmsPinCodeModal } from './SmsPinCodeModal';

const UrlStateParams = {
  redirectUrl: 'redirectUrl',
  encryptedEmail: 'encryptedEmail',
};

const UrlStateConfig = {
  [UrlStateParams.redirectUrl]: StringParam,
  [UrlStateParams.encryptedEmail]: StringParam,
};

export const useRedirectUrl = () => useQueryParam(UrlStateParams.redirectUrl, UrlStateConfig[UrlStateParams.redirectUrl]);
export const useEncryptedEmail = () => useQueryParam(UrlStateParams.encryptedEmail, UrlStateConfig[UrlStateParams.encryptedEmail]);

export const QuickAuth = () => {
  const { logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { isDesktop } = useWindowSize();
  const { isRtl } = useDirection();
  const { addAlert } = useContext(AlertContentContext);
  const [redirectUrl] = useRedirectUrl();
  const [encryptedEmail] = useEncryptedEmail();

  /* encryptedEmailAndSmsPinCode is the first stage result and is needed for second stage */
  const [encryptedEmailAndSmsPinCode, setEncryptedEmailAndSmsPinCode] = useState();
  /* first user inserts personal id, the the pin code modal is showing */
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  /* if we cant verify user's credentials (QUICK_LOGIN_FAKE_ENCRYPTED_PAYLOAD) we will allow him to use a fallback key */
  const [showFallbackKeyInput, setShowFallbackKeyInput] = useState(false);
  /* only for resend pin code */
  const [savedPersonalId, setSavedPersonalId] = useState();

  const {
    formState: { errors },
    register,
    handleSubmit,
    setError,
  } = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(quickLoginSchema),
  });

  const { mutate: testRefreshTokenMutation, isPending: testRefreshTokenIsPending } = useMutation({
    mutationKey: 'testRefreshToken',
    mutationFn: testRefreshToken,
    onSuccess: (data) => {
      // if the refresh token is valid, and connected to the same account, we can redirect the user to the requested page.
      if (data.isRefreshTokenValid) {
        navigate(redirectUrl);
      } else {
        // if the refresh token is not valid, or connected to a different account, we log the user out.
        logout();
      }
    },
    onError: (error) => {
      // error here means we were not able to verify the refresh token, so we log the user out.
      logout();
      NotificationManager.error(t('screens.quickAuth.login'));
    },
  });

  useEffect(() => {
    // first we check if the user is currently logged in, under the same account.
    const currRefreshToken = customLocalStorage.getRefreshToken();
    if (currRefreshToken) {
      testRefreshTokenMutation({ refreshToken: currRefreshToken, encryptedEmail });
    }
  }, [testRefreshTokenMutation, encryptedEmail]);

  useEffect(() => {
    if (!encryptedEmail && !showFallbackKeyInput) {
      setShowFallbackKeyInput(true);
    }
  }, [encryptedEmail, showFallbackKeyInput]);

  /* QUICK LOGIN FLOW */

  const startQuickLoginErrorHandling = useCallback(
    (error) => {
      const errorName = error.response?.data?.error?.name;
      switch (errorName) {
        case 'ID_NOT_MATCH':
          setError('personalId', { type: 'manual', message: t('screens.authentication.errors.ID_NOT_MATCH') }, { shouldFocus: true });
          break;
        case 'QUICK_LOGIN_FAKE_ENCRYPTED_PAYLOAD':
          addAlert({
            title: t('screens.authentication.errors.QUICK_LOGIN_FAKE_ENCRYPTED_PAYLOAD.title'),
            description: t('screens.authentication.errors.QUICK_LOGIN_FAKE_ENCRYPTED_PAYLOAD.message'),
            type: ALERT_TYPES.WARNING,
            error,
          });
          setShowFallbackKeyInput(true);
          break;
        default:
          addAlert(errorAlertBuilder.bug(error));
      }
    },
    [setError, addAlert, setShowFallbackKeyInput, t],
  );

  const { mutate: startQuickLoginMutation, isPending: startQuickLoginIsPending } = useMutation({
    mutationKey: 'startQuickLogin',
    mutationFn: startQuickLogin,
    onSuccess: (data) => {
      const { encryptedEmailAndPinCode } = data;
      setShowPinCodeModal(true);
      setEncryptedEmailAndSmsPinCode(encryptedEmailAndPinCode);
    },
    onError: (error) => {
      startQuickLoginErrorHandling(error);
    },
  });

  const onSubmitPersonalId = (data) => {
    const { personalId, signingKey } = data;
    setSavedPersonalId(personalId);
    startQuickLoginMutation({ encryptedEmail, personalId, signingKey });
  };

  const resendPinCode = () => {
    startQuickLoginMutation({ encryptedEmail, personalId: savedPersonalId });
  };

  /* JSX */
  if (testRefreshTokenIsPending) return <Spinner animation="border" />;

  return (
    <>
      <Container className="h-full d-flex flex-col pt-3 md:max-w-[95vw]">
        <img src={smartbullLogo} id="image" className="w-[166px] md:w-[400px] cursor-pointer my-4" alt="" onClick={() => navigate('/')} />

        <Form onSubmit={handleSubmit(onSubmitPersonalId)} noValidate className="mt-2 max-w-[480px]">
          <h4>{t('screens.authentication.welcomeText')}</h4>

          <p className="text-muted">{t('screens.authentication.descriptionText')}</p>

          <ErrorMessage errors={errors} name="general" render={({ message }) => <Alert variant="danger">{message}</Alert>} />

          <div>
            <Form.Group className="mb-4">
              <Form.Label>{t('screens.authentication.labels.id')}</Form.Label>
              <Form.Control {...register('personalId')} required isInvalid={errors.personalId} style={{ direction: 'ltr' }} pattern="\d*" />
              <ErrorMessage
                errors={errors}
                name="personalId"
                render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
              />
            </Form.Group>
            {showFallbackKeyInput && (
              <Form.Group className="mb-4">
                <Form.Label>{t('screens.authentication.labels.signingKey')}</Form.Label>
                <Form.Control {...register('signingKey')} required isInvalid={errors.signingKey} style={{ direction: 'ltr' }} pattern="\d*" />
                <ErrorMessage
                  errors={errors}
                  name="signingKey"
                  render={({ message }) => <Form.Control.Feedback type="invalid">{t(message)}</Form.Control.Feedback>}
                />
              </Form.Group>
            )}
          </div>

          <Button variant="primary" type="submit">
            {t('screens.authentication.actions.joinOrSignIn')}
          </Button>
        </Form>
      </Container>
      <SmsPinCodeModal
        show={showPinCodeModal}
        redirectUrl={redirectUrl || '/user/offers/manage'}
        encryptedEmailAndSmsPinCode={encryptedEmailAndSmsPinCode}
        onClose={() => {
          setShowPinCodeModal(false);
        }}
        resendPinCode={resendPinCode}
        isResending={startQuickLoginIsPending}
      />
      {isDesktop && (
        <div className="w-full h-full position-absolute top-0 end-0 overflow-hidden -z-50">
          <img src={isRtl ? backgroundHe : backgroundEn} className="position-absolute top-0 end-[0] h-full  z-[-9999]" alt="decorative ellipse" />
        </div>
      )}
    </>
  );
};
