import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { useOfferStateUtils } from 'hooks/offers';

import Box from 'components/UI/Box';

import { OfferSendingModal } from '../OfferSendingModal/OfferSendingModal';
import { SignaturePadModal } from '../SignaturePadModal/SignaturePadModal';

import { useViewAndSignSummary } from './utils';

/**
 * this components dose two things:
 * 1. it renders the html content of the proposal document
 * 2. if the current user should sign the offer, it renders a button that will open the signature pad modal
 */
export const ViewAndSignSummary = () => {
  const { offer } = useContext(OfferCreationContext);
  const { t } = useTranslation();

  const {
    isLoading,
    isError,
    htmlContent,
    showSignaturePadModal,
    setShowSignaturePadModal,
    showOfferSentModal,
    setShowOfferSentModal,
    proposalDocumentContainer,
  } = useViewAndSignSummary();

  const { userCanSign } = useOfferStateUtils({ offer });

  if (isLoading) {
    return (
      <Box title={t('components.ViewAndSignSummary.title')}>
        <Spinner animation="border" />
      </Box>
    );
  }

  if (isError) {
    return (
      <Box title={t('components.ViewAndSignSummary.title')}>
        <div>{t('components.ViewAndSignSummary.error')}</div>
      </Box>
    );
  }

  if (!htmlContent) {
    return null;
  }

  return (
    <fieldset disabled={!userCanSign}>
      <Box title={t('components.ViewAndSignSummary.title')}>
        <div ref={proposalDocumentContainer} className="w-100">
          <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
        </div>
      </Box>
      <OfferSendingModal
        show={showOfferSentModal}
        onHide={() => setShowOfferSentModal(false)}
        auctionId={offer.initiation.auctionDetails.auctionId}
      />
      <SignaturePadModal
        show={showSignaturePadModal}
        close={() => {
          setShowSignaturePadModal(false);
        }}
        setShowOfferSentModal={setShowOfferSentModal}
      />
    </fieldset>
  );
};
