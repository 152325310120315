import { useMutation } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/components/offers/signatures-pad-modal';
import { useCallback, useContext } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SignatureCanvas from 'react-signature-canvas';

import { AlertContentContext } from 'context/Alert';
import { OfferCreationContext } from 'context/offerCreation';

import { signOffer } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { GAEventNames, GAEventStages } from 'shared/const/googleAnalytics';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';
import { isOfferComplete } from 'shared/utils/offers';

import { useOfferStateUtils } from 'hooks/offers';
import { sendGAEvent } from 'hooks/useGoogleAnalytics';

export const SignaturePadModal = ({ show, close, setShowOfferSentModal }) => {
  const { offer, setOffer, offerDataForAnalytics } = useContext(OfferCreationContext);
  const { addAlert } = useContext(AlertContentContext);
  const { t } = useTranslation();
  const { userHasSigned, isUserOfferInitiator } = useOfferStateUtils({ offer });

  const handleOnSuccess = (data) => {
    setOffer(data);

    // if no initiator review is needed, the offer is automatically sent. Therefore we need to show the offer sent modal
    if (isOfferComplete(data)) {
      setShowOfferSentModal(true);
      return;
    }

    if (!isUserOfferInitiator) {
      addAlert({
        title: t('components.SignatoriesTable.successNotification.title'),
        description: t('components.SignatoriesTable.successNotification.description'),
        type: ALERT_TYPES.SUCCESS,
      });
    }
  };

  let sigPad;

  const clearPadAndCloseModal = useCallback(() => {
    if (!!sigPad) sigPad.clear();
    close();
  }, [close, sigPad]);

  const errorHandlingSignOffer = (error) => {
    sendGAEvent(GAEventNames.SIGN_OFFER, GAEventStages.ERROR, offerDataForAnalytics);

    const errorName = getErrorNameFromErrorResponse(error);
    let errorAlertContent = null;
    switch (errorName) {
      case ERROR_CODES.USER_ALREADY_SIGNED:
        errorAlertContent = {
          title: t('components.SignatoriesTable.userAlreadySignedNotification.title'),
          description: t('components.SignatoriesTable.userAlreadySignedNotification.description'),
        };
        break;
      case ERROR_CODES.USER_NOT_SIGNATORY:
        errorAlertContent = {
          title: t('components.SignatoriesTable.userNotSignatoryNotification.title'),
          description: t('components.SignatoriesTable.userNotSignatoryNotification.description'),
        };

        break;
      case ERROR_CODES.USER_NOT_FOUND:
      case ERROR_CODES.OFFER_NOT_FOUND:
      default:
        errorAlertContent = errorAlertBuilder.bug(error);
        break;
    }

    addAlert(errorAlertContent);
  };

  const { mutate: signOfferMutation, isPending: isSigningOffer } = useMutation({
    mutationFn: signOffer,
    mutationKey: 'signOffer',
    onSuccess: handleOnSuccess,
    onError: errorHandlingSignOffer,
    onSettled: clearPadAndCloseModal,
  });

  const handleApprove = async () => {
    if (!sigPad) return;

    if (sigPad.isEmpty()) {
      addAlert({
        title: t('components.SignatoriesTable.emptySignatureNotification.title'),
        description: t('components.SignatoriesTable.emptySignatureNotification.description'),
        type: ALERT_TYPES.ERROR,
      });
      return;
    }

    if (userHasSigned) {
      addAlert({
        title: t('components.SignatoriesTable.userAlreadySignedNotification.title'),
        description: t('components.SignatoriesTable.userAlreadySignedNotification.description'),
        type: ALERT_TYPES.ERROR,
      });
      clearPadAndCloseModal();
    }

    const signaturePngAsString = sigPad.getTrimmedCanvas().toDataURL('image/png');

    const expectedPayload = {
      offerId: offer._id,
      signature: signaturePngAsString,
      signatureFileType: 'PNG',
      dataLayer: offerDataForAnalytics,
    };
    signOfferMutation(expectedPayload);
  };

  return (
    <Modal show={show} onHide={clearPadAndCloseModal} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="flex justify-center">
        <div className="border !border-green-400 w-[450px] h-[200px]">
          <SignatureCanvas
            penColor="green"
            canvasProps={{ width: 450, height: 200, className: 'sigCanvas' }}
            ref={(ref) => {
              sigPad = ref;
            }}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className={isSigningOffer ? 'flex justify-center items-center max-h-10' : ''} onClick={handleApprove}>
          {isSigningOffer ? <Spinner animation="border" /> : t('components.SignatoriesTable.approve')}
        </Button>
        <Button
          onClick={() => {
            if (!!sigPad) sigPad.clear();
          }}
        >
          {t('components.SignatoriesTable.clear')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
