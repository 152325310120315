import { useContext, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { CompanyDetailsContext } from 'context/CompanyDetails';

import { useCompanyNameCompletion } from './useCompanyNameCompletion';

const AddAssociatesModal = () => {
  const { t } = useTranslation();
  const { setShowAddAssociatesModal, showAddAssociatesModal, companyDetails } = useContext(CompanyDetailsContext);
  const [newAssociate, setNewAssociate] = useState(undefined);
  const [newAssociateError, setNewAssociateError] = useState(undefined);
  const [isTouched, setIsTouched] = useState(false);
  const { watch, setValue } = useFormContext();
  const watchAssociates = watch('associates');

  const { fetchBnNumber, isLoading } = useCompanyNameCompletion({
    callback: (associate) => {
      if (!associate) {
        setNewAssociateError(t('components.AddAssociatesModal.companyNotFound'));
        return;
      }

      setNewAssociate(associate);
    },
  });

  const handleAddAssociate = () => {
    const newAssociates = [...watchAssociates, newAssociate];
    setValue('associates', newAssociates, { shouldDirty: true });
    setShowAddAssociatesModal(false);
    setNewAssociate(undefined);
    setNewAssociateError(undefined);
  };

  const handleChange = (e) => {
    /* first clear the error */
    setNewAssociateError(undefined);

    /* then check if the bnNumber is not already in the list */
    const bnNumber = e.target.value;
    const associate = watchAssociates.find((a) => a.bnNumber === bnNumber);
    if (associate) {
      setNewAssociateError(t('components.AddAssociatesModal.alreadyExists'));
      setNewAssociate(undefined);
      return;
    }

    /* then check if the bnNumber is not the same as the company's bnNumber */
    if (bnNumber === companyDetails.bnNumber) {
      setNewAssociateError(t('components.AddAssociatesModal.sameBnNumber'));
      return;
    }

    /* then look for the company in the database */
    fetchBnNumber(bnNumber);

    /* finally set isTouched */
    setIsTouched(true);
  };

  return (
    <Modal
      show={showAddAssociatesModal}
      onHide={() => {
        setShowAddAssociatesModal(false);
      }}
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('components.AddAssociatesModal.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <label>{t('components.AddAssociatesModal.bnNumber')}</label>
        <input type="text" className="form-control" onChange={handleChange} />
        {isLoading && <Spinner animation="border" size="sm" className="mt-2" />}
        {newAssociate && newAssociate.name}
        {isTouched && newAssociateError && <p className="text-danger">{newAssociateError}</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setShowAddAssociatesModal(false)}>
          {t('components.AddAssociatesModal.cancel')}
        </Button>
        <Button variant="primary" onClick={handleAddAssociate} disabled={!newAssociate}>
          {t('components.AddAssociatesModal.submit')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default AddAssociatesModal;
