import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import { useOfferCreationNavigationUtils } from 'hooks/offers/useOfferCreationNavigationUtils';

export const PrevButton = () => {
  const { onPrevClick, hidePrevButton } = useOfferCreationNavigationUtils();
  const { t } = useTranslation();

  if (hidePrevButton) {
    return null;
  }

  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onPrevClick();
  };

  return (
    <Button className="min-w-[164px]" variant="outline-primary" onClick={handleClick}>
      {t('common.previous')}
    </Button>
  );
};
