import { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LimitMaxPercentageFromAuction } from 'components/SharedFormSections/LimitMaxPercentageFromAuction';

export const DefaultLimitationsEditMode = () => {
  const { t } = useTranslation();
  const { watch, setValue, register, getValues } = useFormContext();

  const [initialIsMutualLimitation] = useState(() => getValues('defaultLimitations.isMutualLimitation'));

  const isActiveMaxPercentageFromAuction = watch('defaultLimitations.isActive_maxPercentageFromAuction');
  const currentAssociates = watch('associates');

  useEffect(() => {
    if (!isActiveMaxPercentageFromAuction || currentAssociates.length === 0) {
      // Resetting to false if max percentage from auction is not active or there are no associates
      setValue('defaultLimitations.isMutualLimitation', false);
    } else {
      // Setting to original value
      setValue('defaultLimitations.isMutualLimitation', initialIsMutualLimitation);
    }
  }, [isActiveMaxPercentageFromAuction, currentAssociates.length, setValue, initialIsMutualLimitation]);

  return (
    <div className="mt-3">
      <p className="underline font-bold">{t('screens.company.labels.defaultLimitations')}</p>
      <LimitMaxPercentageFromAuction namespace="defaultLimitations" />

      {currentAssociates.length > 0 && (
        <Form.Group controlId="defaultLimitations.isMutualLimitation">
          <Form.Check
            type="checkbox"
            {...register('defaultLimitations.isMutualLimitation')}
            label={t('screens.company.labels.isMutualLimitation')}
            disabled={!isActiveMaxPercentageFromAuction}
          />
        </Form.Group>
      )}
    </div>
  );
};
