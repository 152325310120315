import PropTypes from 'prop-types';
import { useContext } from 'react';
import { Alert } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AuthContext } from 'context/Auth';
import { CompanyDetailsContext } from 'context/CompanyDetails';

import Box from 'components/UI/Box';
import ModalConfirm from 'components/UI/ModalConfirm';

import { EditModeCompanyTitle } from '../CompanyTitle';
import { CompanyConnections } from '../ViewMode/CompanyConnections';

import { BankAccountsFormTable } from './BankAccountsFormTable';
import { CompanyConnectionEditMode } from './CompanyConnectionEditMode';
import { Employees } from './Employees';
import { EmployeesExt } from './EmployeesExt/EmployeesExt';
import { GeneralInfo } from './GeneralInfo';

export const CompanyForm = ({ onSubmit, isLoading, actionStr, actionVariant, proceedModalContent }) => {
  const { isSBAdmin } = useContext(AuthContext);
  const { showModal, setShowModal, serverError } = useContext(CompanyDetailsContext);

  const { t } = useTranslation();
  const {
    handleSubmit,
    formState: { isDirty, errors },
    trigger,
  } = useFormContext();

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    if (!isDirty) {
      alert(t('screens.company.messages.noChanges'));
      return;
    }

    const isValid = await trigger();
    if (isValid) {
      setShowModal(true);
    } else {
      console.log("Form isn't valid, please check the errors", { errors });
    }
  };

  return (
    <form onSubmit={handleOnSubmit} className="grid grid-cols-1 lg:grid-cols-[1fr_1fr] lg:grid-rows-[auto_1fr] gap-[1rem] mt-[1rem]">
      <EditModeCompanyTitle className="lg:col-span-2" />

      {serverError && (
        <Alert variant="danger" className="lg:col-span-2">
          {t(serverError)}
        </Alert>
      )}
      <Box title={t('screens.company.titles.generalInfo')}>
        <GeneralInfo />
      </Box>

      <Box title={t('screens.company.titles.companySettings')}>
        {isSBAdmin ? <CompanyConnectionEditMode /> : <CompanyConnections editDefaultLimitations={true} />}
      </Box>

      <Box title={t('screens.company.titles.bankAccounts')} className="lg:col-span-2">
        <BankAccountsFormTable />
      </Box>
      <Box title={t('screens.company.titles.companyUsers')} className="lg:col-span-2">
        {isSBAdmin ? <EmployeesExt /> : <Employees />}
      </Box>

      <ModalConfirm
        show={showModal}
        onClose={() => setShowModal(false)}
        onProceed={handleSubmit(onSubmit)}
        title={actionStr}
        variant={actionVariant}
        content={proceedModalContent}
        isLoading={isLoading}
      />
    </form>
  );
};

CompanyForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  actionStr: PropTypes.string.isRequired,
  actionVariant: PropTypes.oneOf(['primary', 'secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark']),
  proceedModalContent: PropTypes.node.isRequired,
};
