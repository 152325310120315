import { useTranslation } from 'react-i18next';

export const NoDefaultLimitations = () => {
  const { t } = useTranslation();

  return (
    <div className="mt-3">
      <p>{t('screens.company.labels.emptyDefaultLimitations')}</p>
    </div>
  );
};
