import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ERROR_CODES } from 'errors/components/companies/new-rhf';
import { useContext, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';

import { createCompanyByAdmin } from 'shared/apis';
import { ALERT_TYPES } from 'shared/const/alerts';
import { contractRoles } from 'shared/const/contractRoles';
import { defaultInvestorType } from 'shared/const/investorTypes';
import { schema } from 'shared/schemas/company';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { CompanyForm } from 'components/CompanyDetails/CompanyForm';
import { buildProceedModalContent } from 'components/CompanyDetails/NewMode/utils';

const NewRHF = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { addAlert } = useContext(AlertContentContext);

  const [showModal, setShowModal] = useState(false);
  const defaultValues = useMemo(() => {
    return {
      companyName: '',
      bnNumber: '',
      domain: '',
      investorType: defaultInvestorType,
      defaultEmailsForAuctionResults: [],
      users: [
        {
          firstName: '',
          lastName: '',
          email: '',
          mobile: '',
          personalId: '',
          role: contractRoles.EMPLOYEE,
        },
      ],
      dividedAccounts: [],
      defaultLimitations: {},
    };
  }, []);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const { mutate, isPending } = useMutation({
    mutationKey: 'createCompanyByAdmin',
    mutationFn: createCompanyByAdmin,
    onSuccess: (data) => {
      setShowModal(false);
      queryClient.invalidateQueries('companies');
      navigate(`/admin/companies/${data._id}`);
    },
    onError: (error) => {
      handleError(error);
      setShowModal(false);
    },
  });

  const handleError = (error) => {
    const errorName = getErrorNameFromErrorResponse(error);
    let errorAlertContent = null;
    switch (errorName) {
      case ERROR_CODES.COMPANY_ALREADY_EXISTS:
        errorAlertContent = {
          title: t('context.company.errors.companyAlreadyExists.title'),
          description: t('context.company.errors.companyAlreadyExists.description'),
          type: ALERT_TYPES.ERROR,
          error: error,
        };
        break;
      case ERROR_CODES.EMAIL_SENDING_FAILED:
        errorAlertContent = {
          title: t('context.company.errors.newCompanySendingEmailFailed.title'),
          description: t('context.company.errors.newCompanySendingEmailFailed.description'),
          type: ALERT_TYPES.WARNING,
          error: error,
        };
        // if the error is email sending failed, the company was still created successfully
        queryClient.invalidateQueries('companies');
        navigate(`/admin/companies/table`);
        break;
      default:
        errorAlertContent = errorAlertBuilder.bug(error);
        break;
    }

    addAlert(errorAlertContent);
  };
  const prepareBankAccountsData = (data) => {
    return data.bankAccounts.map((bankAccount) => {
      return {
        ...bankAccount,
        branchNumber: bankAccount.branchNumber ? Number(bankAccount.branchNumber) : null,
      };
    });
  };

  const onSubmit = (data) => {
    data.bankAccounts = prepareBankAccountsData(data);

    mutate(data);
  };

  const onCancel = (e) => {
    e.preventDefault();
    navigate('/admin/companies/table');
  };

  const watch = methods.watch();
  const proceedModalContent = useMemo(() => {
    const values = methods.getValues();
    return buildProceedModalContent(t, values);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, methods, watch]);

  return (
    <FormProvider {...methods}>
      <CompanyForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isPending}
        actionStr={t('screens.companies.actions.create')}
        actionVariant={'success'}
        proceedModalContent={proceedModalContent}
        showModal={showModal}
        setShowModal={setShowModal}
      />
    </FormProvider>
  );
};

export default NewRHF;
