import { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useErrorRenderer } from '../Offers/utils/ErrorMessageRenderer';

export const LimitMaxPercentageFromAuction = ({ namespace = '' }) => {
  const { t } = useTranslation();
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();
  const { errorDisplay } = useErrorRenderer(errors);

  const isActiveField = namespace ? `${namespace}.isActive_maxPercentageFromAuction` : 'isActive_maxPercentageFromAuction';
  const percentageField = namespace ? `${namespace}.maxPercentageFromAuction` : 'maxPercentageFromAuction';

  const isActiveMaxPercentageFromAuction = watch(isActiveField);

  useEffect(() => {
    if (!isActiveMaxPercentageFromAuction) {
      clearErrors(percentageField);
      setValue(percentageField, null);
    }
  }, [isActiveMaxPercentageFromAuction, setValue, clearErrors, percentageField]);

  return (
    <Form.Group className="mb-2 flex flex-wrap items-center" controlId={isActiveField}>
      <Form.Check type="checkbox" {...register(isActiveField)} className="inline relative" />
      <Form.Label className="m-0"> {t('screens.LimitationsAndContacts.form.limitations.labels.maxAuctionAllocationPercent')} % </Form.Label>
      <Form.Control
        type="number"
        step="0.01"
        {...register(percentageField)}
        disabled={!isActiveMaxPercentageFromAuction}
        isInvalid={!!errors[percentageField]}
        className="inline max-w-[90px] ms-2"
      />
      <Form.Label className="m-0">{t('screens.LimitationsAndContacts.form.limitations.labels.ofTotalAllocatedInAuction')} </Form.Label>
      <div className="w-full">{errorDisplay(percentageField)}</div>
    </Form.Group>
  );
};
