import { useContext } from 'react';
import { Spinner } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import { OfferCreationContext } from 'context/offerCreation';

import AuctionTitle from 'components/Offers/AuctionTitle/AuctionTitle';
import { OfferCreationNavigation } from 'components/Offers/OffersCreationFlow/OfferCreationNavigation';

export const ScreenContent = () => {
  return (
    <>
      <AuctionTitle />
      <div className="pb-[120px]">
        <Outlet />
      </div>
      <OfferCreationNavigation />
    </>
  );
};

export const CreateOfferScreen = () => {
  const { offer, isLoadingOffer } = useContext(OfferCreationContext);

  // ensures component only loads when offer is loaded
  if (!offer || isLoadingOffer) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: '100vh' }}>
        <Spinner animation="border" />
      </div>
    );
  }

  return <ScreenContent />;
};
