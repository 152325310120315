import { useContext, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';

import { LimitMaxPercentageFromAuction } from 'components/SharedFormSections/LimitMaxPercentageFromAuction';

import { useErrorRenderer } from '../utils/ErrorMessageRenderer';

import MutualLimitation from './MutualLimitations';

const OfferLimitations = () => {
  const { t } = useTranslation();
  const { offer } = useContext(OfferCreationContext);
  const {
    register,
    watch,
    formState: { errors },
    setValue,
    clearErrors,
  } = useFormContext();
  const { errorDisplay } = useErrorRenderer(errors);

  const auctionType = offer?.initiation?.auctionDetails?.type;

  /* CLEAR DISABLED FIELDS*/
  const isActiveIssuedCapitalWinningPercentLimit = watch('isActive_issuedCapitalWinningPercentLimit');
  const isActiveMinProceeds = watch('isActive_minProceeds');
  const noUnderwriters = watch('noUnderwriters');
  useEffect(() => {
    if (!isActiveIssuedCapitalWinningPercentLimit) {
      clearErrors('issuedCapitalPresentHoldings');
      clearErrors('limit_issuedCapitalWinningPercentLimit');
      setValue('issuedCapitalPresentHoldings', null);
      setValue('limit_issuedCapitalWinningPercentLimit', null);
    }
    if (!isActiveMinProceeds) {
      clearErrors('limit_minFundraisingVolume');
      setValue('limit_minFundraisingVolume', null);
    }
    if (noUnderwriters) {
      setValue('underwritersAtMinProfitOnly', false);
    }
  }, [isActiveIssuedCapitalWinningPercentLimit, isActiveMinProceeds, noUnderwriters, setValue, clearErrors]);

  let underWriterLimitLabel;
  switch (auctionType) {
    case auctionTypes.INTEREST:
      underWriterLimitLabel = t('screens.LimitationsAndContacts.form.limitations.labels.maxInterest');
      break;
    case auctionTypes.GAP:
      underWriterLimitLabel = t('screens.LimitationsAndContacts.form.limitations.labels.maxGap');
      break;
    case auctionTypes.PRICE:
    default:
      underWriterLimitLabel = t('screens.LimitationsAndContacts.form.limitations.labels.minPrice');
      break;
  }

  return (
    <>
      <h3>{t('screens.LimitationsAndContacts.form.limitations.header')}</h3>
      <LimitMaxPercentageFromAuction />
      <MutualLimitation />

      <Form.Group className="mb-2" controlId="isActive_minProceeds">
        <Form.Check type="checkbox" {...register('isActive_minProceeds')} className="inline relative -bottom-2" />
        <Form.Label> {t('screens.LimitationsAndContacts.form.limitations.labels.minProceeds')} </Form.Label>
        <Form.Control
          type="number"
          {...register('limit_minFundraisingVolume')}
          disabled={!watch('isActive_minProceeds')}
          className="inline max-w-[150px] ms-2"
          isInvalid={!!errors.limit_minFundraisingVolume}
        />
        {errorDisplay('limit_minFundraisingVolume')}
      </Form.Group>

      <Form.Group className="mb-2" controlId="noUnderwriters">
        <Form.Check type="checkbox" {...register('noUnderwriters')} className="inline" />
        <Form.Label> {t('screens.LimitationsAndContacts.form.limitations.labels.noUnderwriters')}</Form.Label>
      </Form.Group>

      <Form.Group className="mb-4" controlId="underwritersAtMinProfitOnly">
        <Form.Check type="checkbox" {...register('underwritersAtMinProfitOnly')} className="inline" disabled={!!watch('noUnderwriters')} />
        <Form.Label>{underWriterLimitLabel}</Form.Label>
      </Form.Group>

      <Form.Group className="mb-4" controlId="additionalComment">
        <Form.Label> {t('screens.LimitationsAndContacts.form.limitations.labels.additionalComment')} </Form.Label>
        <Form.Control {...register('additionalComment')} type="text" className="inline w-90 h-10" />
      </Form.Group>
    </>
  );
};

export default OfferLimitations;
