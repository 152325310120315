import numbro from 'numbro';
import { useContext, useEffect, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { OfferCreationContext } from 'context/offerCreation';

import { AccountsStructures } from 'shared/const/accountsStructures';
import { auctionTypes } from 'shared/const/auctions';
import { calculateLimits } from 'shared/utils/offers';
import $ from 'shared/utils/safeNumbers';

import { useBidInputsDataSetter, useScopeAndAccountsErrors } from 'hooks/offers';

import Box from 'components/UI/Box';

import { BidInput } from './BidInput';
import { YieldPerLimit } from './YieldPerLimit';

export const Bids = () => {
  const { t } = useTranslation();

  return (
    <Box className="" title={t('components.BidTable.title')}>
      <OfferLimitationsText />
      <BidsTable />
    </Box>
  );
};

///////////////////////////////////////////

export const OfferLimitationsText = () => {
  const { t } = useTranslation();
  const { offer } = useContext(OfferCreationContext);
  const { type, biddingLimitations, unitStructures, externalId } = offer?.initiation.auctionDetails;
  const { priceGap, minUnitsPerOrder } = biddingLimitations || {};
  const { minPrice, maxInterest } = useMemo(() => calculateLimits(biddingLimitations, unitStructures), [biddingLimitations, unitStructures]);

  const profitLimitationText = useMemo(() => {
    let profitLimitationText = '';

    profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.auctionTypePrefix');
    profitLimitationText += ' ';
    switch (type) {
      case auctionTypes.INTEREST:
        profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.unitInterest');
        break;
      case auctionTypes.GAP:
        if (['6682788d6768df5c02f9f79f', '6682646778135115b70249c9'].includes(externalId)) {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.unitGapSofr');
        } else {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.unitGap');
        }
        break;
      case auctionTypes.PRICE:
      default:
        profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.unitPrice');
        break;
    }
    profitLimitationText += ' ';

    const minPriceForDisplay = minPrice ? numbro(minPrice).format('0,0$') : null;

    switch (type) {
      case auctionTypes.INTEREST:
        if (minPrice) {
          profitLimitationText += ', ';
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.unitCost', {
            cost: minPriceForDisplay,
          });
        }

        if (!!maxInterest) {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.maxInterest', {
            interest: numbro(maxInterest).formatCurrency({
              thousandSeparated: true,
              currencySymbol: '%',
              currencyPosition: 'postfix',
            }),
          });
        } else {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.noMaxInterest');
        }
        break;

      case auctionTypes.GAP:
        if (minPrice) {
          profitLimitationText += ', ';
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.unitCost', {
            cost: minPriceForDisplay,
          });
        }

        if (!!maxInterest) {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.maxGap', {
            interest: numbro(maxInterest).formatCurrency({
              thousandSeparated: true,
              currencySymbol: '%',
              currencyPosition: 'postfix',
            }),
          });
        } else {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.noMaxGap');
        }
        break;

      case auctionTypes.PRICE:
      default:
        if (!!minPrice) {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.minUnitPrice', {
            price: minPriceForDisplay,
          });
        } else {
          profitLimitationText += t('screens.OrderScopeAndAccounts.form.Scope.noMinUnitPrice');
        }
        break;
    }
    return profitLimitationText;
  }, [t, type, minPrice, maxInterest, externalId]);

  const gapLimitationText = useMemo(() => {
    if (!priceGap) return '';

    let typePlural;
    let formattedPriceGap;
    switch (type) {
      case auctionTypes.INTEREST:
        typePlural = t('screens.OrderScopeAndAccounts.form.Scope.unitInterestPlural');
        formattedPriceGap = numbro(priceGap).formatCurrency({
          thousandSeparated: true,
          currencySymbol: '%',
          currencyPosition: 'postfix',
        });
        break;
      case auctionTypes.GAP:
        typePlural = t('screens.OrderScopeAndAccounts.form.Scope.unitGapPlural');
        formattedPriceGap = numbro(priceGap).formatCurrency({
          thousandSeparated: true,
          currencySymbol: '%',
          currencyPosition: 'postfix',
        });
        break;
      case auctionTypes.PRICE:
      default:
        typePlural = t('screens.OrderScopeAndAccounts.form.Scope.unitPricePlural');
        formattedPriceGap = numbro(priceGap).format('0,0$');
        break;
    }

    return t('screens.OrderScopeAndAccounts.form.Scope.gapLimitationText', {
      typePlural,
      formattedPriceGap,
    });
  }, [t, type, priceGap]);

  const unitStructureList = useMemo(() => {
    if (!unitStructures) {
      return null;
    }

    const unitsDescriptions = unitStructures.map((unitStructure, idx) => {
      const { amount, limit, type, symbol, securityNo } = unitStructure;

      // symbol is required, securityNo is optional...
      const name = securityNo ? `${symbol} (${securityNo})` : symbol;
      const formattedAmount = numbro(amount).format('0,0');
      const formattedPrice = limit ? numbro(limit).format('0,0') : '(-)';

      return (
        <li key={idx}>
          {t('screens.OrderScopeAndAccounts.form.Scope.unitDescription', {
            type: t(`common.${type}`),
            name,
            amount: formattedAmount,
            limit: formattedPrice,
          })}
        </li>
      );
    });

    return <ul className="mb-3 ms-5 list-disc list-inside ">{unitsDescriptions}</ul>;
  }, [t, unitStructures]);

  const minUnitsLimitationText = useMemo(() => {
    if (minUnitsPerOrder > 0) {
      let minUnitsLimitationText = t('screens.OrderScopeAndAccounts.form.Scope.minUnitsNote', { units: numbro(minUnitsPerOrder).format('0,0') });
      return minUnitsLimitationText;
    }

    return '';
  }, [t, minUnitsPerOrder]);

  return (
    <ul className="mb-3 list-disc list-inside">
      <li>{profitLimitationText}</li>
      {gapLimitationText && <li>{gapLimitationText}</li>}
      {minUnitsLimitationText && <li>{minUnitsLimitationText}</li>}
      {unitStructureList && (
        <li>
          {t('screens.OrderScopeAndAccounts.form.Scope.unitStructureNote')}: {unitStructureList}{' '}
        </li>
      )}
    </ul>
  );
};

///////////////////////////////////////////

const BidsTable = () => {
  const { watch } = useFormContext();
  const isDividedAccounts = watch('accountsStructure') === AccountsStructures.DIVIDED;

  return (
    <>
      <Table borderless className="fixed-layout">
        <BidsTableHead />
        {isDividedAccounts ? <DividedAccountsBids /> : <BidsTableInputsAndSums />}
        <OfferSums />
      </Table>
    </>
  );
};

const BidsTableHead = () => {
  const { offer, showYieldAnalysis } = useContext(OfferCreationContext);
  const { type } = offer?.initiation.auctionDetails;
  const { t } = useTranslation();
  const { errorDisplay: bidsNotUniqueErrorDisplay } = useScopeAndAccountsErrors('bids_not_unique');

  let unitName;
  switch (type) {
    case auctionTypes.INTEREST:
      unitName = t('components.OfferVolumeInputTable.form.labels.unitInterest');
      break;
    case auctionTypes.GAP:
      unitName = t('components.OfferVolumeInputTable.form.labels.unitGap');
      break;
    case auctionTypes.PRICE:
    default:
      unitName = t('components.OfferVolumeInputTable.form.labels.unitPrice');
      break;
  }

  return (
    <thead className="text-nowrap">
      {bidsNotUniqueErrorDisplay && (
        <tr>
          <td colSpan={3}>{bidsNotUniqueErrorDisplay}</td>
        </tr>
      )}
      <tr>
        <th>{t('components.OfferVolumeInputTable.form.labels.unitQuantity')}</th>
        <th>{unitName}</th>
        {showYieldAnalysis && <th style={{ color: '#f97316' }}>{t('components.OfferVolumeInputTable.form.labels.yield')}</th>}
        <th>{t('components.OfferVolumeInputTable.form.labels.total')}</th>
      </tr>
    </thead>
  );
};

const BidsTableInputsAndSums = () => {
  const { t } = useTranslation();

  /* VALIDATION ON ALL BIDS */
  const { watch } = useFormContext();
  const { setError: setBidNotUniqueError, clearError: clearBidNotUniqueError } = useScopeAndAccountsErrors('bids_not_unique');
  const { setError: setNoValidBidError, clearError: clearNoValidBidError } = useScopeAndAccountsErrors('no_valid_bid');

  /* VALIDATE EACH BID IS UNIQUE */
  /* 💩❤️ - this code is ugly bug very simple, other more pretty ways where unnecessarily complected, all the 'watch' variables is only for triggering the effect */
  const watchLimit1 = watch(`bid_0_limit`);
  const watchLimit2 = watch(`bid_1_limit`);
  const watchLimit3 = watch(`bid_2_limit`);
  const watchAmount1 = watch(`bid_0_amount`);
  const watchAmount2 = watch(`bid_1_amount`);
  const watchAmount3 = watch(`bid_2_amount`);

  useEffect(() => {
    const limits = [];
    let duplicatedLimits = false;
    for (let i = 0; i < 3; i++) {
      const limit = watch(`bid_${i}_limit`);
      if (!limit || limit === 0) continue;
      if (limits.includes(limit)) {
        duplicatedLimits = true;
      }
      limits.push(limit);
    }

    const errorMsg = t('screens.OrderScopeAndAccounts.form.errors.bids.allBidsDifferent');
    if (duplicatedLimits) {
      setBidNotUniqueError(errorMsg);
    } else {
      clearBidNotUniqueError(errorMsg);
    }
  }, [setBidNotUniqueError, clearBidNotUniqueError, watchLimit1, watchLimit2, watchLimit3, watch, t]);

  useEffect(() => {
    const bid1 = { amount: watchAmount1, limit: watchLimit1 };
    const bid2 = { amount: watchAmount2, limit: watchLimit2 };
    const bid3 = { amount: watchAmount3, limit: watchLimit3 };
    const validBids = [bid1, bid2, bid3].filter((bid) => bid.amount && (bid.limit || bid.limit === 0));
    if (!validBids.length) {
      setNoValidBidError(t('screens.OrderScopeAndAccounts.form.errors.bids.atLeastOneValidBidRequired'));
    } else {
      clearNoValidBidError(t('screens.OrderScopeAndAccounts.form.errors.bids.atLeastOneValidBidRequired'));
    }
  }, [setNoValidBidError, clearNoValidBidError, watchLimit1, watchLimit2, watchLimit3, watchAmount1, watchAmount2, watchAmount3, watch, t]);

  return (
    <tbody>
      <BidTableInputRow index={0} />
      <BidTableInputRow index={1} />
      <BidTableInputRow index={2} />
    </tbody>
  );
};

const DividedAccountsBids = () => {
  useBidInputsDataSetter();

  return <BidsTableInputsAndSums />;
};

const BidTableInputRow = ({ index }) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const isDividedAccounts = watch('accountsStructure') === AccountsStructures.DIVIDED;
  const { showYieldAnalysis } = useContext(OfferCreationContext);

  return (
    <tr>
      <BidInput
        index={index}
        inputType={'amount'}
        disable={isDividedAccounts}
        className="max-w-[150px] min-w-full"
        placeholder={t('components.BidTable.form.placeholders.amount')}
      />
      <BidInput
        index={index}
        inputType={'limit'}
        disable={isDividedAccounts}
        className="max-w-[150px] min-w-full"
        placeholder={t('components.BidTable.form.placeholders.limit')}
      />
      {showYieldAnalysis && <YieldPerLimit index={index} />}
      <BidSum index={index} />
    </tr>
  );
};

const useBidSum = (index) => {
  const { offer } = useContext(OfferCreationContext);
  const { type, biddingLimitations, unitStructures } = offer?.initiation.auctionDetails;
  const { minPrice } = useMemo(() => calculateLimits(biddingLimitations, unitStructures), [biddingLimitations, unitStructures]);
  const { watch } = useFormContext();

  if (index !== 0 && !index) return 0;

  const rowInputsPrefix = `bid_${index}_`;

  const limit = watch(rowInputsPrefix + 'limit');
  const amount = watch(rowInputsPrefix + 'amount');

  const thereIsNoAmount = !amount;
  const thereIsNoValidLimit = type === auctionTypes.PRICE && !limit;
  if (thereIsNoAmount || thereIsNoValidLimit) return 0;

  let pricePerUnit;
  switch (type) {
    case auctionTypes.INTEREST:
    case auctionTypes.GAP:
      pricePerUnit = minPrice;
      break;
    case auctionTypes.PRICE:
      pricePerUnit = limit;
      break;
    default:
      throw new Error(`Unknown auction type: ${type}`);
  }

  const sum = $.multiply(pricePerUnit, amount);
  return sum;
};

const BidSum = ({ index }) => {
  const sum = useBidSum(index);
  const sumForDisplay = numbro(sum).format('0,0$');
  return (
    <>
      <td>{sumForDisplay}</td>
    </>
  );
};

const OfferSums = () => {
  const firstBidCost = useBidSum(0);
  const secondBidCost = useBidSum(1);
  const thirdBidCost = useBidSum(2);
  const costSum = [firstBidCost, secondBidCost, thirdBidCost].reduce((total, cost) => $.add(total, cost), 0);
  const costSumFormatted = numbro(costSum).format('0,0$');
  const { showYieldAnalysis } = useContext(OfferCreationContext);

  const { t } = useTranslation();

  return (
    <tfoot>
      <tr className="font-bold">
        {showYieldAnalysis && <td></td>}
        <td></td>
        <td className="override-text-end">{t('screens.OrderScopeAndAccounts.form.Scope.total')}:</td>
        <td>{costSumFormatted}</td>
      </tr>
    </tfoot>
  );
};
