import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import numbro from 'numbro';
import { Link } from 'react-router-dom';

// ensure empty values are not displayed
export const LinkCell = (value, icon) => {
  if (!value) {
    return '';
  }

  return (
    <div className="flex justify-center">
      <Link to={value} target="_blank">
        <FontAwesomeIcon icon={icon} />
      </Link>
    </div>
  );
};

export const DateCell = (value) => {
  if (!value) {
    return '';
  }

  return dayjs(value).format('DD/MM/YYYY');
};

export const NoWrapCell = (value) => {
  return <div className="text-nowrap">{value}</div>;
};

export const percentFormatCell = (value) => {
  if (value == null || value === '') {
    return '';
  }
  return numbro(value * 100).format({ mantissa: 2 }) + '%';
};
