import { useContext, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { OfferCreationContext } from 'context/offerCreation';

import { auctionTypes } from 'shared/const/auctions';
import { calculateLimits } from 'shared/utils/offers';
import { validateLimitGreaterThanMinPrice, validateLimitLessThanMaxInterest } from 'shared/utils/offers';

import { checkGap } from 'components/Offers/utils/gaps';

export const useIsBidLimitValid = (fieldName) => {
  const { offer } = useContext(OfferCreationContext);
  const { type, biddingLimitations, unitStructures, coupon, sensitivityCalcParams } = offer?.initiation.auctionDetails || {};
  const { max: maxLimit } = sensitivityCalcParams?.limitRange || {};
  const { priceGap } = biddingLimitations || {};
  const { minPrice, maxInterest } = calculateLimits(biddingLimitations, unitStructures);

  const { watch } = useFormContext();
  const value = +watch(fieldName);

  const isLimitValid = useMemo(() => {
    /* REQUIRED AND NUMBER VALIDATION */
    if (!value) return false;
    if (typeof value !== 'number') return false;

    /* gap */
    if (priceGap > 0) {
      const isValidGap = checkGap(value, priceGap, type, { maxInterest, minPrice });
      if (!isValidGap) return false;
    }

    switch (type) {
      case auctionTypes.INTEREST:
      case auctionTypes.GAP:
        if (!validateLimitLessThanMaxInterest(value, maxInterest)) return false;
        break;
      case auctionTypes.PRICE:
        if (!validateLimitGreaterThanMinPrice(value, minPrice)) return false;
        break;
      default:
        throw new Error('Unknown auction type');
    }

    if (type === auctionTypes.GAP && value < coupon * -1) return false;

    if (maxLimit && value > maxLimit * 2) return false;

    return true;
  }, [value, priceGap, type, maxInterest, minPrice, coupon, maxLimit]);

  return { isLimitValid };
};
