import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { ERROR_CODES } from 'errors/components/companies/edit-rhf';
import pick from 'lodash/pick';
import { useCallback, useContext, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AlertContentContext } from 'context/Alert';
import { AuthContext } from 'context/Auth';
import { CompanyDetailsContext } from 'context/CompanyDetails';

import { updateCompanyByCompanyAdmin, updateCompanyBySbAdmin } from 'shared/apis';
import { companyViewModes } from 'shared/const/companyViewModes';
import { schema } from 'shared/schemas/company';
import { getErrorNameFromErrorResponse } from 'shared/utils/error';
import { errorAlertBuilder } from 'shared/utils/errorAlertBuilder';

import { CompanyForm } from 'components/CompanyDetails/CompanyForm';
import { buildProceedModalContent } from 'components/CompanyDetails/EditMode/utils';

const EditRHF = () => {
  const { companyId, companyDetails, companyUsers, setMode, setShowModal } = useContext(CompanyDetailsContext);
  const { isSBAdmin } = useContext(AuthContext);
  const { addAlert } = useContext(AlertContentContext);

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const defaultValues = useMemo(() => {
    const maxPercentageFromAuction = companyDetails.defaultLimitations.maxPercentageFromAuction;

    return {
      companyId: companyId,
      companyName: companyDetails.name,
      bnNumber: companyDetails.bnNumber,
      investorType: companyDetails.investorType,
      domain: companyDetails.domain,
      defaultEmailsForAuctionResults: companyDetails.defaultEmailsForAuctionResults || [],
      users: companyUsers.data.map((user) => {
        const formDetails = pick(user, ['firstName', 'lastName', 'email', 'mobile', 'personalId', 'role', 'detailedStatus', 'isMandatorySignatory']);
        formDetails.userId = user._id;
        return formDetails;
      }),
      bankAccounts: companyDetails.bankAccounts.map((bankAccount) => {
        return pick(bankAccount, ['bank', 'branchNumber', 'accountNumber', 'isCashAccount', 'isSharesAccount']);
      }),
      dividedAccounts: companyDetails.dividedAccounts || [],
      accreditedInvestorCertKey: companyDetails.accreditedInvestorCert?.key,
      accreditedInvestorCertUrl: companyDetails.accreditedInvestorCert?.url,
      accreditedInvestorCertFileName: companyDetails.accreditedInvestorCert?.fileName,
      accreditedInvestorCertExpiryDate: dayjs(companyDetails.accreditedInvestorCert?.expirationDate).format('YYYY-MM-DD'),
      defaultLimitations: {
        isActive_maxPercentageFromAuction: !!maxPercentageFromAuction,
        maxPercentageFromAuction: maxPercentageFromAuction || null,
        isMutualLimitation: companyDetails.defaultLimitations.isMutualLimitation ?? false,
      },
      associates: companyDetails.associates || [],
    };
  }, [companyDetails, companyUsers, companyId]);

  const methods = useForm({
    mode: 'onBlur',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues,
  });

  const onCompanyUpdatedSuccessfully = useCallback(() => {
    queryClient.invalidateQueries('getCompanyDetails');
    queryClient.invalidateQueries('getCompanyUsersTable');
    queryClient.invalidateQueries('getEmploymentState');

    setMode(companyViewModes.VIEW);
  }, [queryClient, setMode]);

  const handleOnSubmitError = (error) => {
    const errorName = getErrorNameFromErrorResponse(error);
    let errorAlertContent = null;
    switch (errorName) {
      case ERROR_CODES.COMPANY_ALREADY_EXISTS:
        errorAlertContent = {
          title: t('context.company.errors.companyAlreadyExists.title'),
          description: t('context.company.errors.companyAlreadyExists.description'),
        };
        break;
      case ERROR_CODES.BANK_MISSING_BRANCH_NUMBER:
        errorAlertContent = {
          title: t('context.company.errors.bankMissingBranchNumber.title'),
          description: t('context.company.errors.bankMissingBranchNumber.description'),
        };
        break;
      case ERROR_CODES.EMAIL_SENDING_FAILED:
        errorAlertContent = {
          title: t('context.company.errors.editCompanySendingEmailFailed.title'),
          description: t('context.company.errors.editCompanySendingEmailFailed.description'),
        };
        // if the error is email sending failed, the company was still updated successfully
        onCompanyUpdatedSuccessfully();
        break;
      default:
        errorAlertContent = errorAlertBuilder.bug(error);
        break;
    }

    addAlert(errorAlertContent);
  };

  const { mutate, isPending } = useMutation({
    mutationKey: 'updateCompany',
    mutationFn: isSBAdmin ? updateCompanyBySbAdmin : updateCompanyByCompanyAdmin,
    onSuccess: onCompanyUpdatedSuccessfully,
    onError: handleOnSubmitError,
    onSettled: () => {
      setShowModal(false);
    },
  });

  const prepareBankAccountsData = (data) => {
    return data.bankAccounts.map((bankAccount) => {
      return {
        ...bankAccount,
        branchNumber: bankAccount.branchNumber ? Number(bankAccount.branchNumber) : null,
      };
    });
  };

  const prepareDefaultLimitations = (defaultLimitations = {}) => {
    return {
      maxPercentageFromAuction: defaultLimitations.maxPercentageFromAuction ?? undefined,
      isMutualLimitation: defaultLimitations.isMutualLimitation ?? undefined,
    };
  };

  const onSubmit = (data) => {
    data.bankAccounts = prepareBankAccountsData(data);
    data.accreditedInvestorCertExpiryDate = dayjs(data.accreditedInvestorCertExpiryDate).toDate();

    data.defaultLimitations = prepareDefaultLimitations(data.defaultLimitations);
    if (data.defaultLimitations.maxPercentageFromAuction) {
      delete data.defaultLimitations.isActive_maxPercentageFromAuction;
    }

    mutate(data);
  };

  const onCancel = (e) => {
    e.preventDefault();
    navigate('/admin/companies/table');
  };

  const watch = methods.watch();
  const proceedModalContent = useMemo(() => {
    const values = methods.getValues();
    return buildProceedModalContent(t, values, defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t, methods, defaultValues, watch]);

  return (
    <FormProvider {...methods}>
      <CompanyForm
        onSubmit={onSubmit}
        onCancel={onCancel}
        isLoading={isPending}
        actionStr={t('screens.companies.actions.update')}
        actionVariant={'warning'}
        proceedModalContent={proceedModalContent}
      />
    </FormProvider>
  );
};

export default EditRHF;
